import axios from 'axios'

const ApiService = {
	removeHeader() {
		axios.defaults.headers.common = {}
	},

	get(resource) {
		return axios.get(resource)
	},

	post(resource, data) {
		return axios.post(resource, data)
	},
	formData(resource, data) {
		return axios.post(resource, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
	},

	put(resource, data) {
		return axios.put(resource, data)
	},

	delete(resource) {
		return axios.delete(resource)
	},
	print(resourse) {
		return axios.get(resourse, {
			responseType: 'blob'
		})
	},
	printtemp(resourse, data) {
		return axios.post(resourse, data, {
			responseType: 'blob'
		})
	},
}

export default ApiService