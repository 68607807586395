import ApiService from "./api.service";
const ServiceService ={
    GetAll(){
        return ApiService.get(`/Service/GetAll`)
    },
    GetList(Search,SortCoumn,OrderType,PageNumber,PageLimit){
        return ApiService.get(`/Service/GetList?Search=${Search}&SortColumn=${SortCoumn}&OrderType=${OrderType}&PageNumber=${PageNumber}&PageLimit=${PageLimit}`)
    },
    Get(id){
        return ApiService.get(`/Service/Get?id=${id}`)
    },
    Update(data){
        return ApiService.post(`/Service/Update`,data)
    },
    Delete(id){
        return ApiService.delete(`/Service/Delete?id=${id}`)
    }
}
export default ServiceService